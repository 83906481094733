import React, { useState, useEffect } from 'react';
import { Text, View, TouchableOpacity, Image, Linking } from 'react-native';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import styles from './style';
import * as Animatable from 'react-native-animatable';
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import { getAuth } from "firebase/auth";

export default function Home({ navigation }) {

    const auth = getAuth();
    const user = auth.currentUser;
    const [email, setEmail] = useState("");
    const [nome, setNome] = useState("");
    const [saudacao, setSaudacao] = useState("");

    const [open, setOpen] = useState(false);
    const [retorno1, setRetorno1] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [retorno3, setRetorno3] = useState([]);
    const [color, setColor] = useState("#000");
    const [color2, setColor2] = useState("#000");
    const [color3, setColor3] = useState("#000");
    const [relatorio, setRelatorio] = useState(false);
    const [caso, setCaso] = useState(false);
    const [resumo, setResumo] = useState(false);
    const [certificado, setCertificado] = useState(false);
    const [casoClinico, setCasoClinico] = useState(false);
    const [trabalho, setTrabalho] = useState(false);
    const [prof, setProf] = useState([]);

    useEffect(() => {
        // Atualiza o email quando o usuário é carregado
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail(profile.email);
            });
        }
    }, []);

    function SaudacaoHora() {
        // Define a saudação com base na hora do dia
        const horaAtual = new Date().getHours();

        if (horaAtual >= 0 && horaAtual < 12) {
            setSaudacao("Buenos días");
        } else if (horaAtual >= 12 && horaAtual < 18) {
            setSaudacao("Buenas tardes");
        } else {
            setSaudacao("Buenas noches");
        }
    }

    const getResumos1 = async () => {
        // Obtém os resumos do Grupo 10
        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("grupo", "==", "Grupo10"), where("nomeForm", "!=", ""));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status,
                data: doc.data().data
            };
            array.push(Obj);
        });

        setRetorno1(array);
    }

    // Funções semelhantes para obter resumos dos outros grupos
    const getResumos2 = async () => {
        // Obtém os resumos do Grupo 11
        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("grupo", "==", "Grupo11"), where("nomeForm", "!=", ""));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status,
                data: doc.data().data
            };
            array.push(Obj);
        });

        setRetorno2(array);
    }
    const getResumos3 = async () => {
        // Obtém os resumos do Grupo 12
        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("grupo", "==", "Grupo12"), where("nomeForm", "!=", ""));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status,
                data: doc.data().data
            };
            array.push(Obj);
        });

        setRetorno3(array);
    }


    const getProf = async () => {
        // Obtém informações do professor atual
        const db = getFirestore();
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("Professor", "==", 'Sim'), where("email", "==", email));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().formP,
                status: doc.data().status
            };
            array.push(Obj);
        });

        setProf(array);
    }

    const getUsuario = async () => {
        // Obtém informações do usuário atual
        const db = getFirestore();
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setNome(doc.data().nome);
        });
    }

    useEffect(() => {
        // Carrega dados iniciais ao entrar na página
        getProf();
        getResumos1();
        getResumos2();
        getResumos3();
        getUsuario();
        SaudacaoHora();
    }, [email]);

    useFocusEffect(
        React.useCallback(() => {
            // Atualiza dados ao focar na tela
            getResumos1();
            getResumos2();
            getResumos3();
            SaudacaoHora();
        }, [])
    );

    useEffect(() => {
        // Atualiza cores dos ícones com base nos resumos disponíveis
        if (retorno1.length >= 1) {
            setColor("#00B0C7");
        }

        if (retorno2.length >= 1) {
            setColor2("#00B0C7");
        }

        if (retorno3.length >= 1) {
            setColor3("#00B0C7");
        }
    }, [retorno1, retorno2, retorno3]);

    return (
        <View style={styles.container}>

            <View style={styles.header}>
                {/* Cabeçalho da página */}
                <View>
                    <Text style={styles.Title}>Home</Text>
                </View>

                {/* Menu para visualizar como Suporte ou Professor */}
                {email === "suporte@rayanepinto.com" && open === false &&
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => setOpen(true)}>
                        <Text style={{ fontSize: 25, textAlign: "center", color: "#FFF" }}>Visualizando como Suporte 📚</Text>
                    </TouchableOpacity>
                }

                {email === "suporte@rayanepinto.com" && open === true &&
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => setOpen(false)}>
                        <Text style={{ fontSize: 25, textAlign: "center", color: "#FFF" }}>Visualizando como Professor 📝</Text>
                    </TouchableOpacity>
                }

                {/* Botão para sair (Logout) */}
                <Animatable.View
                    animation="fadeInDown"
                    style={{ flexDirection: 'row-reverse', marginTop: 20, marginRight: 40, position: 'absolute', top: 0, right: 0 }}>
                    <TouchableOpacity onPress={() => navigation.navigate("Login")}>
                        <MaterialCommunityIcons
                            name="location-exit"
                            size={70}
                            color="#ffffff"
                            style={{ justifyContent: 'center', alignContent: 'center' }}
                        />
                    </TouchableOpacity>
                </Animatable.View>
            </View>

            {/* Imagem de fundo */}
            <View style={styles.divImgBack}>
                <Animatable.Image
                    animation="fadeInDown"
                    style={styles.imgBack} source={{
                        uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                    }} />
            </View>

            {/* Modal de Resumos */}
            {resumo === true &&
                <View style={styles.modal}>
                    <View style={{ margin: 30, flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ color: "#000", textAlign: "center", fontSize: 30, }}>Resumos</Text>
                        <TouchableOpacity style={styles.X} onPress={() => { setResumo(false) }}>X</TouchableOpacity>
                    </View>

                    <View style={styles.botaoModal}>

                        {/* Botões para os diferentes grupos de resumos */}
                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("HomeGrupo10")}>
                            <MaterialCommunityIcons
                                name="note-text-outline"
                                size={90}
                                color={color}
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Grupo 10</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("HomeGrupo11")}>
                            <MaterialCommunityIcons
                                name="note-text-outline"
                                size={90}
                                color={color2}
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Grupo 11</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("HomeGrupo12")}>
                            <MaterialCommunityIcons
                                name="note-text-outline"
                                size={90}
                                color={color3}
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Grupo 12</Text>
                        </TouchableOpacity>

                    </View>
                </View>
            }

            {/* Modal de Relatórios */}
            {relatorio === true &&
                <View style={styles.modal}>
                    <View style={{ margin: 30, flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ color: "#000", textAlign: "center", fontSize: 30, }}>Relatórios</Text>
                        <TouchableOpacity style={styles.X} onPress={() => { setRelatorio(false) }}>X</TouchableOpacity>
                    </View>

                    <View style={styles.botaoModal}>

                        {/* Botões para os diferentes relatórios */}
                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("RelatorioG10")}>
                            <MaterialCommunityIcons
                                name="chart-box-outline"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Grupo 10</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("RelatorioG11")}>
                            <MaterialCommunityIcons
                                name="chart-box-outline"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Grupo 11</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("RelatorioG12")}>
                            <MaterialCommunityIcons
                                name="chart-box-outline"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Grupo 12</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("RelatorioGeral")}>
                            <MaterialCommunityIcons
                                name="chart-box-outline"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Relatório{"\n"}Geral</Text>
                        </TouchableOpacity>

                    </View>
                </View>
            }

            {/* Modal de Certificados */}
            {certificado === true &&
                <View style={styles.modal}>
                    <View style={{ margin: 30, flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ color: "#000", textAlign: "center", fontSize: 30, }}>Certificados</Text>
                        <TouchableOpacity style={styles.X} onPress={() => { setCertificado(false) }}>X</TouchableOpacity>
                    </View>

                    <View style={styles.botaoModal}>

                        {/* Botões para os diferentes relatórios */}
                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("Formula")}>
                            <MaterialCommunityIcons
                                name="certificate"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Fórmula</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("Intercept")}>
                            <MaterialCommunityIcons
                                name="certificate"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Intercept</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            }

            {/* Modal de Certificados */}
            {trabalho === true &&
                <View style={styles.modal}>
                    <View style={{ margin: 30, flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ color: "#000", textAlign: "center", fontSize: 30, }}>Certificados</Text>
                        <TouchableOpacity style={styles.X} onPress={() => { setTrabalho(false) }}>X</TouchableOpacity>
                    </View>

                    <View style={styles.botaoModal}>

                        {/* Botões para os diferentes relatórios */}
                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("Trabalho")}>
                            <MaterialCommunityIcons
                                name="book-education"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>OX24</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("Trabalho25")}>
                            <MaterialCommunityIcons
                                name="book-education"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>OX25</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            }

            {/* Modal de Casos Clínicos */}
            {casoClinico === true &&
                <View style={styles.modal}>
                    <View style={{ margin: 30, flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ color: "#000", textAlign: "center", fontSize: 30, }}>Casos Clínicos</Text>
                        <TouchableOpacity style={styles.X} onPress={() => { setCasoClinico(false) }}>X</TouchableOpacity>
                    </View>

                    <View style={styles.botaoModal}>

                        {/* Botões para os diferentes relatórios */}
                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => prof.length === 1 ? navigation.navigate("FilaProfessor") : navigation.navigate("Fila")}>
                            <MaterialCommunityIcons
                                name="tooth"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>Español</Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.botaoGrupos} onPress={() => navigation.navigate("FilaIngles")}>
                            <MaterialCommunityIcons
                                name="tooth"
                                size={90}
                                color="#000"
                                style={styles.iconGrupos}
                            />
                            <Text style={styles.MenuModulos}>English</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            }

            {/* Conteúdo principal da página */}
            {relatorio !== true && caso !== true && certificado !== true && resumo !== true && casoClinico != true && trabalho !== true &&
                <View>

                    {/* Área de cadastro de alunos (apenas para o email específico) */}
                    {email === 'jonathaspereira673@gmail.com' &&
                        <View style={styles.DivAreaAluno}>
                            <TouchableOpacity style={styles.BottomAreaAluno} onPress={() => { Linking.openURL("https://osf-cad.netlify.app/") }}>
                                <Text style={styles.textBottomAreaAluno}>Cadastro de Alunos</Text>
                            </TouchableOpacity>
                        </View>
                    }


                    <View style={{ position: "absolute", right: 0, top: 0, marginRight: 40, marginTop: 30, }}>
                        <TouchableOpacity style={{ padding: 20, borderRadius: 5, backgroundColor: "#E5CD00", justifyContent: "center", alignItems: "center" }}
                            onPress={() => navigation.navigate("Links")}
                        >
                            <Text style={{ color: "#000", fontSize: 25, fontFamily: "Arial", fontWeight: "bold" }}>Links para envio de casos clínicos (DAO)</Text>
                        </TouchableOpacity>
                    </View>

                    {/* Imagem principal e saudação */}
                    <View style={styles.divImg}>
                        <Animatable.Image
                            animation="fadeInDown"
                            style={styles.img} source={{
                                uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO%26VERDE.png?alt=media&token=9a1d6f1f-f601-4297-93e2-f10f86dc8d52',
                            }} />
                        <Text style={{ color: "#FFF", fontSize: 30, textAlign: "center", fontWeight: "bold", marginTop: -110, width: 450, }}>{saudacao} {nome}, es muy bueno verte aquí</Text>
                    </View>

                    {/* Botões principais da página */}
                    <View style={styles.botaoDocs}>

                        {/* Botão de Envio de Resumos */}
                        <TouchableOpacity onPress={() => { setResumo(true) }}>
                            <View style={styles.botao1}>
                                {(retorno1.length + retorno2.length + retorno3.length) >= 1 &&
                                    <Animatable.Text style={{ width: 300, textAlign: 'right', marginBottom: -20 }} animation="zoomIn" easing="ease-out" iterationCount="infinite">
                                        <MaterialCommunityIcons
                                            name="progress-alert"
                                            size={40}
                                            color="#F7E00C"
                                        />
                                    </Animatable.Text>
                                }
                                <MaterialCommunityIcons
                                    name="note-text-outline"
                                    size={160}
                                    color="#FFF"
                                    style={styles.icon}
                                />
                                <Text style={styles.textBotao}>Envio de Resumos</Text>
                            </View>
                        </TouchableOpacity>

                        {/* Botão de Casos Clínicos (apenas visível para não suporte) */}
                        {email !== "suporte@rayanepinto.com" &&
                            <TouchableOpacity onPress={() => { setCasoClinico(true) }}>
                                <View style={styles.botao2}>
                                    <MaterialCommunityIcons
                                        name="tooth"
                                        size={180}
                                        color="#000"
                                        style={styles.icon}
                                    />
                                    <Text style={styles.textBotao3}>Casos Clínicos</Text>
                                </View>
                            </TouchableOpacity>
                        }

                        {/* Botão de Relatórios */}
                        <TouchableOpacity onPress={() => { setRelatorio(true) }}>
                            <View style={styles.botao3}>
                                <MaterialCommunityIcons
                                    name="chart-box-outline"
                                    size={180}
                                    color="#000"
                                    style={styles.icon}
                                />
                                <Text style={styles.textBotao3}>Relatórios</Text>
                            </View>
                        </TouchableOpacity>

                        {/* Botão de Certificados */}
                        <TouchableOpacity onPress={() => { setCertificado(true) }}>
                            <View style={styles.botao4}>
                                <MaterialCommunityIcons
                                    name="certificate"
                                    size={180}
                                    color="#000"
                                    style={styles.icon}
                                />
                                <Text style={styles.textBotao3}>Certificados</Text>
                            </View>
                        </TouchableOpacity>
                    </View>

                    <View style={{ justifyContent: 'center', alignItems: "center", flexDirection: "row", marginBottom: 50 }}>

                        {/* Botão de Cadastro para o Marketplace */}
                        <TouchableOpacity onPress={() => navigation.navigate("MarketplaceCriacao")}>
                            <View style={styles.botao6}>
                                <MaterialCommunityIcons
                                    name="card-account-details-star-outline"
                                    size={160}
                                    color="#FFF"
                                    style={styles.icon}
                                />
                                <Text style={styles.textBotao}>Portal de Odontologos</Text>
                            </View>
                        </TouchableOpacity>

                        {/* Botão de Envio de Trabalhos */}
                        <TouchableOpacity onPress={() => {setTrabalho(true)}}>
                            <View style={styles.botao5}>
                                <Image
                                    style={styles.logo}
                                    source={{
                                        uri: "https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FLOGO.svg?alt=media&token=0e78f5bd-8fd8-4a9c-87e6-319063a9a401",
                                    }} />
                                <Text style={styles.textBotao}>Envío de Trabajos</Text>
                            </View>
                        </TouchableOpacity>

                        {/* Botão de Envio de Trabalhos */}
                        <TouchableOpacity onPress={() => navigation.navigate("HomeTestemunho")}>
                            <View style={styles.botao7}>
                                <MaterialCommunityIcons
                                    name="movie-open-play-outline"
                                    size={160}
                                    color="#FFF"
                                    style={styles.icon}
                                />
                                <Text style={styles.textBotao}>Concurso de Testemunhos</Text>
                            </View>
                        </TouchableOpacity>
                    </View>

                </View>
            }

        </View>
    );
}